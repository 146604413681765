import PlainHtml from '@swe/shared/ui-kit/components/plain-html';

import { PolicyKey, usePolicy } from 'common/use-cases/policies';

const PrivacyPolicy = () => {
  const { policy } = usePolicy(PolicyKey.PrivacyPolicy);

  if (!policy?.content) return null;

  return <PlainHtml html={policy.content} />;
};

export { PrivacyPolicy };
