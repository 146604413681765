import reducePreloads from '@swe/shared/network/helpers/reduce-preloads';

import { ApplicationPage } from 'app/types';
import { Page } from 'common/components/page';
import PageHeader from 'common/components/page-header';
import { PrivacyPolicy } from 'common/containers/privacy-policy';
import { Routes } from 'common/router/constants';
import GetShopConfigEndpoint from 'endpoints/shop/get-shop-config';

const TITLE = 'Privacy Policy';

const PrivacyPolicyPage: ApplicationPage = () => {
  return (
    <Page>
      <PageHeader
        title={TITLE}
        defaultBackRoute={Routes.Agreements}
        enableMarginBottom
      />
      <PrivacyPolicy />
    </Page>
  );
};

PrivacyPolicyPage.getMeta = () => ({ title: TITLE });

PrivacyPolicyPage.preload = async ({ headers, queryClient }) => {
  return reducePreloads([GetShopConfigEndpoint.preload({}, { headers }, queryClient)]);
};

export default PrivacyPolicyPage;
